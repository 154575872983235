import React from "react";
import { Layout } from "..";
import { Reviews } from "../../components";
import { Container } from "./styled";

function Adminstracion() {
  return (
    <Layout>
      <Container>
        <div className="hero-section" id="contactForm">
          <div className="hero-section-content">
            <div className="hero-section-content-text">
              <h1>Servicios Contables Aministrativos</h1>
              <p>Somos tu Aliado Estratégico en Contabilidad e Impuestos.</p>
            </div>
            <div className="hero-section-content-form">
              <form action="https://formsubmit.co/your@email.com" method="POST">
                <div className="personal-data">
                  <input
                    placeholder="NOMBRE COMPLETO"
                    type="text"
                    name="nombre"
                    required
                  />
                  <input placeholder="MAIL" type="email" name="mail" required />
                </div>
                <input
                  placeholder="ASUNTO"
                  type="text"
                  name="asunto"
                  required
                />
                <input
                  placeholder="MENSAJE"
                  className="message"
                  type="text"
                  name="mensaje"
                  required
                />
                <button type="submit" className="btn-primary">
                  CONSULTAR
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="cta-section">
          <h1>El mejor Servicio Mensual adaptado a vos</h1>
          <hr className="separation-line" />
          <h3>Nuestras soluciones para tu empresa!</h3>
          <p>
            Ofrecemos una amplia gama de servicios en el sector laboral,
            incluyendo la implementación de procesos, el desarrollo y gestión de
            proyectos y más.
          </p>
          <a href="#contactForm" className="btn-primary">
            CONSULTANOS
          </a>
        </div>
        <div className="home-options-container">
          <div className="image-container">
            <img
              src="../../assets/services-1.jpg"
              loading="lazy"
              alt="bussines images"
            />
          </div>
          <div className="about-container">
            <h2>Nuestros Servicios Aministrativos</h2>
            <ul>
              <li>Implementación de procesos.</li>
              <li>Desarrollo y Gestión de proyectos.</li>
              <li>Implementación de sistemas informáticos.</li>
              <li>Control interno.</li>
              <li>
                Armado de estructura administrativa y circuitos administrativos.
              </li>
              <p>
                <b>Registro de marca:</b>
              </p>
              <li>
                Informes sobre disponibilidad de la marca a registrar.
                Seguimiento del expediente hasta la concesión.
              </li>
              <li>Defensa de oposiciones efectuadas por terceros.</li>
              <li>Registro de marcas en el exterior.</li>
            </ul>
          </div>
        </div>
        <div className="home-options-container reversed">
          <div className="image-container">
            <img
              src="../../assets/services-2.jpg"
              loading="lazy"
              alt="bussines images"
            />
          </div>
          <div className="about-container">
            <h2>¿Querés asesorarte previamente?</h2>
            <p>
              Iniciar en el ámbito contable e impositivo puede tener sus
              desafíos. En nuestro estudio, no solo ofrecemos servicios
              contables impositivos de primer nivel, sino que también estamos
              aquí para asesorarte.
            </p>
            <p>
              Antes de que te comprometas con un servicio mensual, te brindamos
              toda la orientación que necesitás. Y si estás buscando realizar un
              trámite de inscripción en AFIP, ¡también te cubrimos!
            </p>
            <a href="#contactForm" className="btn-primary ">
              CONSULTANOS
            </a>
          </div>
        </div>
        {/* <Reviews /> */}
      </Container>
    </Layout>
  );
}

export default Adminstracion;
