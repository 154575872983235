import React from "react";
import { Layout } from "../../containers";
import { Reviews } from "../../components";
import { Container } from "./styled";

function Contact() {
  return (
    <Layout>
      <Container>
        <div className="hero-section">
          <div className="hero-section-content">
            <h1>Contacto</h1>
            <p>Comunicate con nosotros.</p>
          </div>
        </div>
        <div className="contact-section" id="contactForm">
          <div className="contact-info-container">
            <h4>CONTACTO</h4>
            <p>Paraguay 557 Piso 1ª (1057 CABA)</p>
            <p>(011) 4315 7171</p>
            <p>+ 54 11 5718 2388</p>
            <p>
              <b>Redes sociales</b>
            </p>
            <div className="social-media-container">
              <ul className="footer-list">
                <li>
                  <a
                    href="https://facebook.com/ESTUDIORITVOAR"
                    target="_blank"
                    className="twitter_link"
                    rel="noreferrer"
                  >
                    <img src="/assets/logo-facebook.png" alt="logo facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/estudioritvo"
                    target="_blank"
                    className="twitter_link"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/logo-instagram.png"
                      alt="logo instagram"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://linkedin.com/estudioritvo"
                    target="_blank"
                    className="twitter_link"
                    rel="noreferrer"
                  >
                    <img src="/assets/logo-linkedin.png" alt="logo linkedin" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/estudioritvo"
                    target="_blank"
                    className="twitter_link"
                    rel="noreferrer"
                  >
                    <img src="/assets/logo-twitter.png" alt="logo twitter" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="contact-form-container">
            <h4>!Envianos tu consulta!</h4>
            <p>
              Por medio de este formulario háganos llegar su consulta y/o
              sugerencia.
            </p>
            <form
              action="https://formsubmit.co/info@estudioritvo.com.ar"
              method="POST"
            >
              <div className="personal-data">
                <input
                  placeholder="NOMBRE COMPLETO"
                  type="text"
                  name="nombre"
                  required
                />
                <input placeholder="MAIL" type="email" name="mail" required />
              </div>
              <input placeholder="ASUNTO" type="text" name="asunto" required />
              <input
                placeholder="MENSAJE"
                className="message"
                type="text"
                name="mensaje"
                required
              />
              <button type="submit" className="btn-primary">
                ENVIAR
              </button>
            </form>
          </div>
        </div>
        <iframe
          title="stuido map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.270098594095!2d-58.37759392425996!3d-34.597330972956584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccaca5a45b9c9%3A0x98585e19f287dca1!2sParaguay%20557%20piso%201%2C%20C1057%20CABA!5e0!3m2!1sen!2sar!4v1701288026357!5m2!1sen!2sar"
          style={{ width: "90%", height: "400px", border: "0", margin: "auto" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </Container>
    </Layout>
  );
}

export default Contact;
