import React from "react";
import { Layout } from "../../containers";
import { Container } from "./styled";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Layout>
      <Container>
        <div className="login-container">
          <div className="logo-container">
            <img
              className="navbarLogo"
              src="../../assets/Logo estudio un paso mas.png"
              alt="Un Paso Más logo"
            />
            <h1>Un Paso Más </h1>
          </div>
          <div className="form-container">
            <form>
              <input placeholder="mail" />
              <input placeholder="contraseña" />
              <button type="submit" className="btn-primary">
                INGRESAR
              </button>
            </form>
            <p>
              <Link href="#">¿Olvidaste la clave?</Link>
            </p>
            <p>
              ¿No tenés cuenta?{" "}
              <Link href="register" className="register-link">
                Registrate acá
              </Link>
            </p>
          </div>
        </div>
        <button onClick={() => loginWithRedirect()}>Log In</button>
      </Container>
    </Layout>
  );
}

export default Login;
