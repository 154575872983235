import styled from "styled-components";

export const Container = styled.footer`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  max-width:1920px
  height: 10vh;
  margin: 0 auto;
  padding: 0 5%;
  background-color: #f6f6f6;
  p {
    color: #6f7a80;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 700;
    a{
      text-decoration:none;
      color: #6f7a80;
    }
  }
  .footer-list_containers {
    flex-direction: row;
    width: 90%;
    align-items: flex-start;
  }
  .footer-info-container {
    ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 0;
      li {
        width: 100%;
        list-style: none;
        margin: 0.5rem 0;
        font-size: 16px;
        a {
          text-decoration: none;
          color: #6f7a80;
          font-weight: 700;
          img{
          width: 30px;
          height: 30px;
          margin-right: 5px;
        }
        }
      }
    }
  }
  @media (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
    p {
      font-size: 18px;
    }
    .footer-list_containers {
      flex-direction: row;
      width: auto;
    }
  }
`;
