import React, { useState } from "react";
import { Container } from "./styled";
import { Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Navbar = () => {
  const [clientWindowHeight, setClientWindowHeight] = useState("");
  const { isAuthenticated } = useAuth0();
  const { logout, loginWithRedirect } = useAuth0();

  const history = useHistory();

  return (
    <Container
      className={`finish-button-nav ${
        clientWindowHeight >= 15 ? "isNotTop" : null
      }`}
    >
      <div
        className="logo-container"
        onClick={() => {
          history.push("/");
          window.scroll(0, 0);
        }}
      >
        <Link to="/">
          <img
            className="navbarLogo"
            src="../../assets/Logo estudio un paso mas.png"
            alt="Un Paso Más logo"
          />
        </Link>
      </div>
      <ul>
        <li className="servicis">
          Servicios
          <ul id="servicis-submenu" className="servicis-submenu">
            <li>
              <Link
                to="/estrategia"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                Estrategia
              </Link>
            </li>
            <li>
              <Link
                to="/contabilidad"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                Contabilidad
              </Link>
            </li>
            <li>
              <Link
                to="/impuestos"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                Impuestos
              </Link>
            </li>
            <li>
              <Link
                to="/laboral"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                Laboral
              </Link>
            </li>
            <li>
              <Link
                to="/societario"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                Societario
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link
            to="/contacto"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            Contacto
          </Link>
        </li>
        {isAuthenticated ? (
          <li>
            <Link
              to="/dashboard"
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              Recursos
            </Link>
          </li>
        ) : null}
        {isAuthenticated ? (
          <li>
            <button
              className="btn-primary ghost"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              Salir
            </button>
          </li>
        ) : (
          <li>
            <button
              onClick={() => loginWithRedirect()}
              id="logoutButton"
              className="btn-primary"
            >
              Ingresar
            </button>
          </li>
        )}
      </ul>
    </Container>
  );
};
export default Navbar;
