import styled from "styled-components";

export const Container = styled.div`
  color: #000;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-repeat: no-repeat;
  img {
    width: auto;
    height: 80%;
  }
`;
