import React from "react";
import { Container } from "./styled";
import { Layout } from "..";
import { Reviews } from "../../components";
const Laboral = () => {
  return (
    <Layout>
      <Container>
        <div className="hero-section" id="contactForm">
          <div className="hero-section-content">
            <div className="hero-section-content-text">
              <h1>Servicio de Liquidación de Sueldos</h1>
              <p>Somos tu Aliado Estratégico en Contabilidad e Impuestos.</p>
            </div>
            <div className="hero-section-content-form">
              <form action="https://formsubmit.co/your@email.com" method="POST">
                <div className="personal-data">
                  <input
                    placeholder="NOMBRE COMPLETO"
                    type="text"
                    name="nombre"
                    required
                  />
                  <input placeholder="MAIL" type="email" name="mail" required />
                </div>
                <input
                  placeholder="ASUNTO"
                  type="text"
                  name="asunto"
                  required
                />
                <input
                  placeholder="MENSAJE"
                  className="message"
                  type="text"
                  name="mensaje"
                  required
                />
                <button type="submit" className="btn-primary">
                  CONSULTAR
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="cta-section">
          <h1>El mejor Servicio Mensual adaptado a vos</h1>
          <hr className="separation-line" />
          <h3>Soluciones especializadas para la gestión del personal</h3>
          <p>
            Ofrecemos una amplia gama de servicios especializados en el área
            laboral, incluyendo asesoramiento laboral integral, liquidación de
            sueldos y jornales mensuales o quincenales y más.
          </p>
          <a href="#contactForm" className="btn-primary">
            CONSULTANOS
          </a>
        </div>
        <div className="home-options-container">
          <div className="image-container">
            <img
              src="../../assets/services-1.jpg"
              loading="lazy"
              alt="bussines images"
            />
          </div>
          <div className="about-container">
            <h2>Nuestros Servicios Contables Laborales</h2>
            <ul>
              <li>Asesoramiento laboral integral.</li>
              <li>
                Liquidación de sueldos y jornales mensuales o quincenales.
              </li>
              <li>Liquidación de Cargas sociales.</li>
              <li>
                Análisis y aplicación de los distintos convenios colectivos de
                trabajo, en la liquidación de sueldos y jornales, de acuerdo a
                cada actividad.
              </li>
              <li>
                En el Rubro Empresas Constructoras: Inscripción en IERIC,
                Gestión de libretas de Fondo de Cese Laboral, apertura de
                cuentas bancarias para el depósito del mismo.{" "}
              </li>
              <li>Rúbrica de Hojas móviles o libro de sueldos y jornales.</li>
              <li>Atención de requerimientos de fiscalización y control.</li>
              <li>
                Tramitación de altas / bajas / modificaciones del personal.
              </li>
              <li>Cálculo de indemnizaciones.</li>
            </ul>
          </div>
        </div>
        <div className="home-options-container reversed">
          <div className="image-container">
            <img
              src="../../assets/services-2.jpg"
              loading="lazy"
              alt="bussines images"
            />
          </div>
          <div className="about-container">
            <h2>¿Querés asesorarte previamente?</h2>
            <p>
              Iniciar en el ámbito contable e impositivo puede tener sus
              desafíos. En nuestro estudio, no solo ofrecemos servicios
              contables impositivos de primer nivel, sino que también estamos
              aquí para asesorarte.
            </p>
            <p>
              Antes de que te comprometas con un servicio mensual, te brindamos
              toda la orientación que necesitás. Y si estás buscando realizar un
              trámite de inscripción en AFIP, ¡también te cubrimos!
            </p>
            <a href="#contactForm" className="btn-primary ">
              CONSULTANOS
            </a>
          </div>
        </div>
        {/* <Reviews /> */}
      </Container>
    </Layout>
  );
};

export default Laboral;
