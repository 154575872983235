import styled from "styled-components";

export const Container = styled.div`
  background-color: #f6f6f6;
  text-align: center;
  h1 {
    margin: 0;
    padding-top: 2rem;
    font-size: clamp(2rem, 4vw, 4rem);
  }
  .images-slider-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    .image-gallery {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: fit-content;
    }
    .image-gallery-container-extra {
      width: 100vw;
      overflow: hidden;
    }
    .image-gallery-container {
      display: flex;
      width: 100%;
      overflow: scroll;
      padding-bottom: 3rem;
      scrollbar-color: red yellow;
      &::-webkit-scrollbar {
        background: unset;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #13235f;
      }
      .slider-card-extra {
        width: 100vw;
        .slider-card {
          width: 84vw;
          margin: 0 1rem;
          background: #fff;
          padding: 1rem;
          border-radius: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          border: 1px solid #13235f;
          min-height: 500px;
          .person-image {
            border: 3px solid #13235f;
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
          h4 {
            margin: 0;
          }
          p {
            font-size: 24px;
            text-align: center;
          }
        }
      }
    }
  }
  @media screen and (min-width: 867px) {
    .images-slider-container
      .image-gallery-container
      .slider-card-extra
      .slider-card {
      width: 30vw;
    }
  }
`;
