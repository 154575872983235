import React from "react";
import { Container } from "./styled";
import { Layout } from "..";
import { Reviews } from "../../components";
const Societario = () => {
  return (
    <Layout>
      <Container>
        <div className="hero-section" id="contactForm">
          <div className="hero-section-content">
            <div className="hero-section-content-text">
              <h1>Servicios Contables Societarios</h1>
              <p>Somos tu Aliado Estratégico en Contabilidad e Impuestos.</p>
            </div>
            <div className="hero-section-content-form">
              <form action="https://formsubmit.co/your@email.com" method="POST">
                <div className="personal-data">
                  <input
                    placeholder="NOMBRE COMPLETO"
                    type="text"
                    name="nombre"
                    required
                  />
                  <input placeholder="MAIL" type="email" name="mail" required />
                </div>
                <input
                  placeholder="ASUNTO"
                  type="text"
                  name="asunto"
                  required
                />
                <input
                  placeholder="MENSAJE"
                  className="message"
                  type="text"
                  name="mensaje"
                  required
                />
                <button type="submit" className="btn-primary">
                  CONSULTAR
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="cta-section">
          <h1>El mejor Servicio Mensual adaptado a vos</h1>
          <hr className="separation-line" />
          <h3>Asesoramiento integral para la gestión societaria</h3>
          <p>
            Ofrecemos una amplia gama de servicios contables especializados en
            el área societaria, incluyendo la constitución de sociedades de
            responsabilidad limitada y más.
          </p>
          <a href="#contactForm" className="btn-primary">
            CONSULTANOS
          </a>
        </div>
        <div className="home-options-container">
          <div className="image-container">
            <img
              src="../../assets/services-1.jpg"
              loading="lazy"
              alt="bussines images"
            />
          </div>
          <div className="about-container">
            <h2>Nuestros Servicios Contables Societarios</h2>
            <ul>
              <li>
                Constitución S.R.L. (sociedad de responsabilidad limitada) S.A.
                y S.A.S. con tramite en 24 horas lista para operar, con cuit, y
                libros rubricados.
              </li>
              <li>Reformas de Sociedades comerciales.</li>
              <li>Aumentos de capital.</li>
              <li>Reorganizaciones societarias.</li>
              <li>Fusiones, Escisiones, Transformaciones. </li>
              <li>
                60 Ley 19.550 – Inscripción de renuncia/nombramiento/renovación
                de Autoridades.
              </li>
              <li>Cambios de Domicilio Legal.</li>
              <li>
                Cambios de Jurisdicción (desde y hacia I.G.J. o D.P.P.J.).
              </li>
              <li>Liquidación, nombramientos de liquidador.</li>
              <li>Cancelación Registral.</li>
              <li> Solicitud certificado de vigencia.</li>
              <li>Solicitud de fotocopias certificadas de expedientes.</li>
              <li>Certificaciones y testimonios</li>
              <li>Conflictos societarios.</li>
              <li>Rubrica de Libros</li>
            </ul>
          </div>
        </div>
        <div className="home-options-container reversed">
          <div className="image-container">
            <img
              src="../../assets/services-2.jpg"
              loading="lazy"
              alt="bussines images"
            />
          </div>
          <div className="about-container">
            <h2>¿Querés asesorarte previamente?</h2>
            <p>
              Iniciar en el ámbito contable e impositivo puede tener sus
              desafíos. En nuestro estudio, no solo ofrecemos servicios
              contables impositivos de primer nivel, sino que también estamos
              aquí para asesorarte.
            </p>
            <p>
              Antes de que te comprometas con un servicio mensual, te brindamos
              toda la orientación que necesitás. Y si estás buscando realizar un
              trámite de inscripción en AFIP, ¡también te cubrimos!
            </p>
            <a href="#contactForm" className="btn-primary ">
              CONSULTANOS
            </a>
          </div>
        </div>
        {/* <Reviews /> */}
      </Container>
    </Layout>
  );
};

export default Societario;
