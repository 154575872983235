import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 82vh;
  max-width: 1920px;
  background-color: #fff;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-container {
    width: 80vw;
    height: fit-content;
    padding: 1rem;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 0.2);
    .logo-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 30px;
        width: auto;
        margin-right: 1rem;
      }
      h1 {
        font-size: clamp(18px, 4vw, 30px);
      }
    }
    .form-container {
      text-align: center;
      form {
        display: flex;
        flex-direction: column;
        input {
          width: 90%;
          padding: 1rem;
          margin: 1rem auto;
          border: 1px solid #cccccc;
        }
        .btn-primary {
          width: 90%;
          margin: 1rem auto;
          max-width: unset;
          max-height: unset;
          height: 40px;
        }
      }
    }
    a {
      text-decoration: none;
      color: #000;
    }
    .register-link {
      color: #13235f;
      font-weight: 700;
    }
  }

  @media screen and (min-width: 867px) {
    height: 90vh;
    .login-container {
      width: 480px;
      .logo-container img {
        height: 50px;
      }
    }
  }
`;
