import React from "react";
import { Container } from "./styled";

const Footer = () => {
  return (
    <Container>
      <p>
        © Copyright 2023 | Un Paso Más | <a href="/blog">Blog</a>
      </p>
      <div className="flex_center_space-between footer-list_containers">
        <div className="footer-info-container">
          <ul className="footer-list">
            <li>
              <a
                href="https://facebook.com/ESTUDIORITVOAR"
                target="_blank"
                className="twitter_link"
                rel="noreferrer"
              >
                <img src="/assets/logo-facebook.png" alt="logo facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/estudioritvo"
                target="_blank"
                className="twitter_link"
                rel="noreferrer"
              >
                <img src="/assets/logo-instagram.png" alt="logo instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://linkedin.com/estudioritvo"
                target="_blank"
                className="twitter_link"
                rel="noreferrer"
              >
                <img src="/assets/logo-linkedin.png" alt="logo linkedin" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/estudioritvo"
                target="_blank"
                className="twitter_link"
                rel="noreferrer"
              >
                <img src="/assets/logo-twitter.png" alt="logo twitter" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
