import React from "react";
import { Layout } from "../../containers";
import { Reviews, LinkedinPosts } from "../../components";
import { Container } from "./styled";

function Home() {
  return (
    <Layout>
      <Container>
        <div className="hero-section lazy">
          <div className="hero-section-content">
            <h1>Un Paso Más</h1>
            <p>Acompañamos tu proyecto.</p>
            <a href="#contactForm" className="btn-primary">
              Consultanos
            </a>
          </div>
        </div>
        <div className="services-section">
          <div className="services-item">
            <img
              src="../../assets/services-icon-1.svg"
              alt="Icono Admistración un paso mas"
              loading="lazy"
            />
            <h4>Estrategia</h4>
            <ul>
              <li>Implementación de procesos.</li>
              <li>Desarrollo y Gestión de proyectos.</li>
              <li>Implementación de sistemas informáticos.</li>
              <li>Control interno.</li>
              <li>
                Armado de estructura administrativa y circuitos administrativos.
              </li>
            </ul>
            <a href="/estrategia" className="btn-primary ghost">
              Ver más
            </a>
          </div>
          <div className="services-item">
            <img
              src="../../assets/services-icon-2.svg"
              alt="Icono Contabilidad un paso mas"
            />
            <h4>Contabilidad</h4>
            <ul>
              <li>Asesoramiento contable integral</li>
              <li>
                Implementación de sistemas informáticos contables y de costos.
              </li>
              <li>Análisis de cuentas contables.</li>
              <li>Reportes periódicos de resultados.</li>
              <li>Balance General Anual.</li>
              <li>
                Presentación balances en Organismos de contralor: IGJ, DPPJ.
              </li>
            </ul>
            <a href="/contabilidad" className="btn-primary ghost">
              Ver más
            </a>
          </div>
          <div className="services-item">
            <img
              src="../../assets/services-icon-3.svg"
              alt="Icono Impuestos un paso mas"
            />
            <h4>Impuestos</h4>
            <ul>
              <li>Asesoramiento impositivo integral.</li>
              <li>
                Inscripciones para el inicio de la actividad Personas Físicas o
                Personas Jurídicas.
              </li>
              <li>
                Solicitud de certificado de retención para residentes del
                exterior.
              </li>
              <li>Regularizaciones impositivas, previsionales y aduaneras.</li>
              <li>
                Liquidaciones impositivas de: Personas Físicas y Jurídicas.
              </li>
              <li>Recategorización Monotributistas.</li>
            </ul>
            <a href="/impuestos" className="btn-primary ghost">
              Ver más
            </a>
          </div>
          <div className="services-item">
            <img
              src="../../assets/services-icon-4.svg"
              alt="Icono Admistración un paso mas"
            />
            <h4>Laboral</h4>
            <ul>
              <li>Asesoramiento laboral integral</li>
              <li>
                Liquidación de sueldos y jornales mensuales o quincenales.
              </li>
              <li>Liquidación de Cargas sociales.</li>
              <li>Rúbrica de Hojas móviles o libro de sueldos y jornales.</li>
              <li>Atención de requerimientos de fiscalización y control.</li>
              <li>
                Tramitación de altas / bajas / modificaciones del personal.
              </li>
            </ul>
            <a href="/laboral" className="btn-primary ghost">
              Ver más
            </a>
          </div>
          <div className="services-item">
            <img
              src="../../assets/services-icon-5.svg"
              alt="Icono Admistración un paso mas"
            />
            <h4>Societario</h4>
            <ul>
              <li>
                Constitución S.R.L. (sociedad de responsabilidad limitada) S.A.
                y S.A.S. con tramite en 24 horas lista para operar, con cuit, y
                libros rubricados.
              </li>
              <li>Reformas de Sociedades comerciales.</li>
              <li>Aumentos de capital.</li>
              <li>Reorganizaciones societarias.</li>
              <li>Fusiones, Escisiones, Transformaciones.</li>
            </ul>
            <a href="/societario" className="btn-primary ghost">
              Ver más
            </a>
          </div>
        </div>
        <div className="home-options-container">
          <div className="image-container">
            <img
              src="../../assets/home-2.jpg"
              loading="lazy"
              alt="bussines images"
            />
          </div>
          <div className="about-container">
            <h4>NOSOTROS</h4>
            <hr className="separation-line left" />
            <p>
              Bienvenido a nuestro mundo, donde la experiencia y el conocimiento
              se fusionan para impulsar tu éxito en el ámbito financiero y
              comercial. Somos un equipo de profesionales apasionados y
              dedicados, todos contadores con una especialización destacada en
              áreas clave como comercio electrónico, comercio físico, desarrollo
              de proyectos y estrategias de ventas internacionales.
            </p>
          </div>
        </div>
        <LinkedinPosts />
        <div className="corporate-section">
          <div className="corporate-section-extra_container">
            <div className="corporate-item">
              <div className="data-container">
                <img
                  src="../../assets/vision-icon.svg"
                  width="746,708 px"
                  height="573,008 px"
                  alt="reunion en un estudio contable"
                  loading="lazy"
                />
                <h4>Nuestra Misión</h4>
                <p>
                  En el corazón de nuestra misión se encuentra el compromiso con
                  tu crecimiento y prosperidad. Entendemos las complejidades de
                  los negocios modernos y estamos aquí para proporcionarte
                  soluciones sólidas y estratégicas.
                </p>
              </div>
            </div>
            <div className="corporate-item">
              <div className="data-container">
                <img
                  src="../../assets/focus-icon.svg"
                  alt="Estudio contable Un Paso Más foco icono"
                />
                <h4>Experticia en Comercio Electrónico y Físico</h4>
                <p>
                  Desde la era digital hasta el entorno físico, navegamos por
                  los desafíos del comercio electrónico y físico con destreza.
                  Ofrecemos asesoramiento experto para maximizar tus
                  oportunidades de crecimiento y asegurar el éxito a largo plazo
                  de tu negocio.
                </p>
              </div>
            </div>
            <div className="corporate-item">
              <div className="data-container">
                <img
                  src="../../assets/values-icon.svg"
                  alt="Estudio contable Un Paso Más valores icono"
                />
                <h4>Desarrollo de Proyectos con Visión de Futuro</h4>
                <p>
                  Nos destacamos en la creación y desarrollo de proyectos con
                  visión de futuro. Desde la concepción hasta la implementación,
                  trabajamos de la mano contigo para convertir tus ideas en
                  realidades tangibles y sostenibles.
                </p>
              </div>
            </div>
            <div className="corporate-item">
              <div className="data-container">
                <img
                  src="../../assets/sales-icon.jpg"
                  alt="Estudio contable Un Paso Más valores icono"
                />
                <h4>Pilares de Estrategias de Ventas Internacionales</h4>
                <p>
                  Como pioneros en estrategias de ventas internacionales, te
                  guiamos en la expansión de tu negocio más allá de las
                  fronteras. Nuestra experiencia global y conocimiento del
                  mercado te ayudarán a superar barreras y alcanzar nuevos
                  horizontes.
                </p>
              </div>
            </div>
            <div className="corporate-item">
              <div className="data-container">
                <img
                  src="../../assets/Compromise-icon.jpg"
                  alt="Estudio contable Un Paso Más valores icono"
                />
                <h4>Compromiso</h4>
                <p>
                  Nos enfocamos en proporcionar servicios de la más alta
                  calidad, adaptados a tus necesidades específicas. Tu éxito es
                  nuestro éxito, y trabajamos para garantizar resultados que
                  superen tus expectativas. <br />
                  Descubre cómo podemos potenciar tu visión y llevar tu negocio
                  al siguiente nivel. ¡Únete a nosotros en el viaje hacia el
                  éxito empresarial!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-section">
          <h4>
            Contactanos y genera una reunion <br className="mobile-only" /> para
            contarnos tu proyecto!
          </h4>
          <a href="#contactForm" className="btn-primary">
            Consultanos
          </a>
        </div>
        <div className="contact-section" id="contactForm">
          <div className="contact-info-container">
            <h4>CONTACTO</h4>
            <p>Paraguay 557 Piso 1ª (1057 CABA)</p>
            <p>(011) 4315 7171</p>
            <p>+ 54 11 5718 2388</p>
            <iframe
              title="stuido map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.270098594095!2d-58.37759392425996!3d-34.597330972956584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccaca5a45b9c9%3A0x98585e19f287dca1!2sParaguay%20557%20piso%201%2C%20C1057%20CABA!5e0!3m2!1sen!2sar!4v1701288026357!5m2!1sen!2sar"
              style={{ width: "90%", height: "250px", border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="contact-form-container">
            <h4>!Envianos tu consulta!</h4>
            <p>
              Por medio de este formulario háganos llegar su consulta y/o
              sugerencia.
            </p>
            <form
              action="https://formsubmit.co/info@estudioritvo.com.ar"
              method="POST"
            >
              <div className="personal-data">
                <input
                  placeholder="NOMBRE COMPLETO"
                  type="text"
                  nombre="nombre"
                  required
                />
                <input placeholder="MAIL" type="email" mail="mail" required />
              </div>
              <input
                placeholder="ASUNTO"
                type="text"
                asunto="asunto"
                required
              />
              <input
                placeholder="MENSAJE"
                className="message"
                type="text"
                mensaje="mensaje"
                required
              />
              <button type="submit" className="btn-primary">
                ENVIAR
              </button>
            </form>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Home;
