import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #fff;
  margin: auto;
  margin-top: 10vh;
  text-align: center;
  .dashboard-header {
    p {
      max-width: 250px;
      margin: 0 auto 2rem;
    }
  }
  .folder-conatiner {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 1rem auto;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px 0;
    .folder_item-conatiner {
      width: 100%;
      flex-basis: 24%;
      height: fit-content;
      display: flex;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      &.item-1 {
        background: url("/assets/dashboard-icon.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.item-2 {
        background: url("/assets/dashboard-icon-2.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.item-3 {
        background: url("/assets/dashboard-icon-3.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.item-4 {
        background: url("/assets/dashboard-icon-4.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.item-5 {
        background: url("/assets/dashboard-icon-5.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .folder_item-extraconatiner {
        background: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        padding: 8rem 0 0 1rem;
        text-align: left;
        a {
          height: 100%;
          width: 100%;
          text-decoration: none;
          cursor: pointer;
          h5 {
            color: #fff;
            text-decoration: none;
            margin: 0;
          }
          p {
            color: #fff;
            text-decoration: underline;
            margin: 0.5;
            font-size: 14px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 867px) {
    .dashboard-header {
      p {
        max-width: 450px;
        margin: 0 auto 2rem;
      }
    }
    .folder-conatiner {
      flex-direction: row;
      .folder_item-conatiner {
        width: 20%;
      }
    }
  }
`;
