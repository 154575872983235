import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #fff;
  margin: auto;
  .hero-section {
    background: url("/assets/home-m-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    margin-top: 10vh;
    height: 50vh;
    .hero-section-content {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      h1 {
        margin: 0;
        color: #13235f;
        font-size: clamp(2.3rem, 6vw, 6rem);
      }
      p {
        color: #13235f;
        margin: 0 0 2rem 0;
        font-size: clamp(18px, 4vw, 30px);
      }
    }
  }
  .services-section {
    width: 90%;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .services-item {
      background: #fff;
      flex-basis: 16%;
      border-radius: 24px;
      padding: 1rem;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 0.2);
      display: flex;
      flex-flow: column;
      height: auto;
      margin-bottom: 1rem;
      img {
        height: 80px;
        width: auto;
        margin: 1rem 0;
        align-self: flex-start;
      }
      h4 {
        color: #13235f;
        margin: 0;
        font-weight: 400;
        font-size: clamp(1.5rem, 4vw, 1.2rem);
      }
      ul {
        padding: 0 1rem;
        flex: 1;
        li {
          list-style-image: url("/assets/tick.svg");
        }
      }
    }
  }
  .home-options-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 3rem auto;
    .image-container {
      width: 100%;
      img {
        width: 90%;
      }
    }
    .about-container {
      width: 100%;
      h2 {
        margin: 0;
      }
      h4 {
        color: #13235f;
        margin-bottom: 0;
      }
      p {
        width: 100%;
        font-size: 18px;
        text-align: justify;
      }
    }
  }
  .corporate-section {
    .corporate-section-extra_container {
      width: 90%;
      margin: 1rem auto 0 auto;
      display: flex;
      flex-direction: column;
      padding: 0 2%;
      row-gap: 25px;
      padding: 3rem 0;
      .corporate-item {
        color: #fff;
        position: relative;
        width: 96%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
          rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
        border-radius: 16px;
        padding: 1rem;
        .data-container {
          display: grid;
          grid-template-columns: 100%;
          grid-template-rows: 20% 15% 65%;
          height: 100%;
          img {
            height: 50px;
            width: auto;
          }
          h4 {
            color: #000;
            margin: 1rem 0;
            font-size: 1rem;
          }
          p {
            color: #000;
            margin: 1rem 0;
            display: flex;
          }
        }
      }
    }
  }
  .cta-section {
    background: linear-gradient(
      90deg,
      rgba(63, 61, 154, 0.8758096988795518) 0%,
      rgba(78, 76, 162, 1) 100%
    );
    color: #fff;
    padding: 1rem;
    text-align: center;
    a:hover {
      color: #fff;
    }
  }
  .contact-section {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    margin: 1rem auto;
    .contact-info-container {
      width: 100%;
    }
    .contact-form-container {
      width: 100%;
      form {
        display: flex;
        flex-flow: column;
        input {
          padding: 1rem;
          margin: 1rem 0;
          border-radius: 14px;
          border: 1px solid #cccccc;
          &.message {
            height: 60px;
          }
        }
        .personal-data {
          display: flex;
          justify-content: space-between;
          input {
            width: 40%;
          }
        }
        button {
          border-radius: 0;
          max-height: 100px;
          height: 50px;
        }
      }
    }
  }
  @media screen and (min-width: 867px) {
    .corporate-section .corporate-section-extra_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: auto;
      flex-wrap: wrap;
      min-height: 600px;
      .corporate-item {
        flex-basis: 17%;
        .data-container {
          height: 100%;
          img {
            height: 80px;
            width: auto;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1000px) {
    .hero-section {
      background: url("/assets/home-1.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 10vh;
      height: 70vh;
    }
    .services-section {
      flex-direction: row;
      margin-top: -5%;
      .services-item {
        padding: 1rem;
      }
    }
    .home-options-container {
      flex-direction: row;
      .image-container {
        width: 50%;
      }
      .about-container {
        width: 50%;
        p {
          width: 70%;
        }
      }
    }
    .cta-section {
      padding: 3rem;
    }
    .contact-section {
      flex-direction: row;
      .contact-info-container {
        width: 50%;
      }
      .contact-form-container {
        width: 50%;
      }
    }
  }
`;
