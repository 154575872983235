import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  text-align: center;
  background-color: #f6f6f6;
  padding: 1rem 0;
  max-width: 1920px;
  .posts-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    margin-bottom: 4rem;
    iframe {
      width: 90vw;
      padding: 0 1rem;
    }
  }
  @media (min-width: 867px) {
    .posts-container {
      flex-direction: row;
      iframe {
        width: 24vw;
      }
    }
  }
`;
