import React from "react";
import { Layout } from "..";
import { Container404 } from "../../components";
function Four0four() {
  return (
    <Layout>
      <Container404 />
    </Layout>
  );
}

export default Four0four;
