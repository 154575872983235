import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  Home,
  Four0four,
  Adminstracion,
  Impuestos,
  Contabilidad,
  Laboral,
  Societario,
  Contact,
  Login,
  Dashboard,
  Blog,
} from "./containers";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/estrategia" exact component={Adminstracion} />
        <Route path="/contabilidad" exact component={Contabilidad} />
        <Route path="/laboral" exact component={Laboral} />
        <Route path="/societario" exact component={Societario} />
        <Route path="/impuestos" exact component={Impuestos} />
        <Route path="/contacto" exact component={Contact} />
        <Route path="/login" exact component={Login} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/blog" exact component={Blog} />
        <Route path="*">
          <Four0four />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
