import React from "react";
import { Container } from "./styled";
import { Link } from "react-router-dom";

const Container404 = () => {
  return (
    <Container>
      <img src="../../assets/error.jpg" alt="Un Paso Más 404" />
      <p>Esta pagina no esta disponible</p>
      <Link to="/" className="btn-primary">
        Volvé al incio
      </Link>
    </Container>
  );
};

export default Container404;
