import React from "react";
import { Layout } from "..";
import { Container } from "./styled";

function Dashboard() {
  return (
    <Layout>
      <Container>
        <h1>Articulos</h1>
        <div className="post-container">
          <iframe
            src="https://www.linkedin.com/embed/feed/update/urn:li:share:7104230136010448896"
            height="790"
            width="504"
            frameborder="0"
            allowfullscreen=""
            title="Publicación integrada"
          ></iframe>
        </div>
        <div className="post-container">
          <iframe
            src="https://www.linkedin.com/embed/feed/update/urn:li:share:7104230136010448896"
            height="790"
            width="504"
            frameborder="0"
            allowfullscreen=""
            title="Publicación integrada"
          ></iframe>
        </div>
        <div className="post-container">
          <iframe
            src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7111854494048489472"
            height="411"
            width="504"
            frameborder="0"
            allowfullscreen=""
            title="Publicación integrada"
          ></iframe>
        </div>
        <div className="post-container">
          <iframe
            src="https://www.linkedin.com/embed/feed/update/urn:li:share:7111945788171153408"
            height="463"
            width="504"
            frameborder="0"
            allowfullscreen=""
            title="Publicación integrada"
          ></iframe>
        </div>
        <div className="post-container">
          <iframe
            src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7117006918388203521"
            height="497"
            width="504"
            frameborder="0"
            allowfullscreen=""
            title="Publicación integrada"
          ></iframe>
        </div>
        <div className="post-container">
          <iframe
            src="https://www.linkedin.com/embed/feed/update/urn:li:share:7138379192013955072"
            height="1114"
            width="504"
            frameborder="0"
            allowfullscreen=""
            title="Publicación integrada"
          ></iframe>
        </div>
        <div className="post-container">
          <iframe
            src="https://www.linkedin.com/embed/feed/update/urn:li:share:7136560039653195776"
            height="1030"
            width="504"
            frameborder="0"
            allowfullscreen=""
            title="Publicación integrada"
          ></iframe>
        </div>
        <div className="post-container">
          <iframe
            src="https://www.linkedin.com/embed/feed/update/urn:li:share:7135415262891130881"
            height="1177"
            width="504"
            frameborder="0"
            allowfullscreen=""
            title="Publicación integrada"
          ></iframe>
        </div>
      </Container>
    </Layout>
  );
}

export default Dashboard;
