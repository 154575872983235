import React, { useState } from "react";
import { Container } from "./styled";

const LinkedinPosts = () => {
  return (
    <Container>
      <h4>
        Lecturas imprescindibles para sus <br />
        necesidades financieras.
      </h4>
      <div className="posts-container">
        <iframe
          src="https://www.linkedin.com/embed/feed/update/urn:li:share:7104230136010448896"
          height="500"
          width="504"
          frameborder="0"
          allowfullscreen=""
          title="Publicación integrada"
        ></iframe>
        <iframe
          src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7111854494048489472"
          height="500"
          width="504"
          frameborder="0"
          allowfullscreen=""
          title="Publicación integrada"
        ></iframe>
        <iframe
          src="https://www.linkedin.com/embed/feed/update/urn:li:share:7111945788171153408"
          height="500"
          width="504"
          frameborder="0"
          allowfullscreen=""
          title="Publicación integrada"
        ></iframe>
        <iframe
          src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7117006918388203521"
          height="500"
          width="504"
          frameborder="0"
          allowfullscreen=""
          title="Publicación integrada"
        ></iframe>
        <iframe
          src="https://www.linkedin.com/embed/feed/update/urn:li:share:7138379192013955072"
          height="500"
          width="504"
          frameborder="0"
          allowfullscreen=""
          title="Publicación integrada"
        ></iframe>
      </div>
      <a href="/blog" className="btn-primary ghost">
        Ver más
      </a>
    </Container>
  );
};
export default LinkedinPosts;
