import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 90%;
  max-width: 1920px;
  background-color: #fff;
  margin: auto;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  h1 {
    width: 100%;
  }
  .post-container {
    background-color: #eee;
    height: fit-content;
    width: 100%;
    margin-top: 2rem;
    iframe {
      width: 100%;
    }
  }
  &::after {
    content: "";
    flex: auto;
  }
  @media screen and (min-width: 867px) {
    flex-direction: row;
    .post-container {
      width: 24%;
    }
  }
`;
