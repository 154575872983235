import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #fff;
  margin: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  .hero-section {
    background: url("/assets/contact-hero-m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    width: 100%;
    height: 30vh;
    margin-top: 10vh;
    .hero-section-content {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      h1 {
        margin: 0;
        color: #13235f;
      }
      p {
        color: #13235f;
        margin: 0 0 2rem 0;
        font-size: clamp(18px, 4vw, 30px);
      }
    }
  }
  .contact-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 90%;
    margin: 1rem auto;
    .contact-info-container {
      width: 100%;
      padding: 2rem;
      height: fit-content;
      ul {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 0;
        li {
          width: 100%;
          list-style: none;
          margin: 0.5rem 0;
          font-size: 16px;
          a {
            text-decoration: none;
            color: #6f7a80;
            font-weight: 700;
            img {
              width: 30px;
              height: 30px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .contact-form-container {
      width: 100%;
      background: #f5f5f5;
      padding: 2rem;
      h4 {
        margin-top: 0;
      }
      form {
        display: flex;
        flex-flow: column;
        input {
          padding: 1rem;
          margin: 1rem 0;
          border-radius: 14px;
          border: 1px solid #cccccc;
          &.message {
            height: 60px;
          }
        }
        .personal-data {
          display: flex;
          justify-content: space-between;
          input {
            width: 40%;
          }
        }
        button {
          border-radius: 0;
          max-height: 100px;
          height: 50px;
        }
      }
    }
  }
  @media (min-width: 867px) {
    .hero-section {
      background: url("/assets/contact-hero.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      height: 40vh;
      max-height: 400px;
    }

    .contact-section {
      flex-direction: row;
      .contact-info-container {
        width: 50%;
        ul {
          width: 30%;
        }
      }
      .contact-form-container {
        width: 50%;
      }
    }
  }
`;
