import React from "react";
import { Layout } from "..";
import { Container } from "./styled";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router-dom";

function Dashboard() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  return (
    <Layout>
      {!isAuthenticated && !isLoading ? (
        <Redirect to="/" />
      ) : (
        <Container>
          <div className="dashboard-header">
            <h1>Bienvenido {user?.nickname}</h1>
            <p>
              Herramientas, guías y plantillas que te ayudarán a manejar tus
              finanzas y contabilidad de manera más fácil.
            </p>
          </div>
          <div className="folder-conatiner">
            <div className="folder_item-conatiner item-1">
              <div className="folder_item-extraconatiner">
                <a
                  href="https://drive.google.com/drive/folders/1sZzIImBZMvNwlkIxlLwuLN7xgMNpGtsV?usp=drive_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h5>Administración</h5>
                  <p>Ver recursos</p>
                </a>
              </div>
            </div>
            <div className="folder_item-conatiner item-2">
              <div className="folder_item-extraconatiner">
                <a
                  href="https://drive.google.com/drive/folders/1tPcGsvog5bPeWHUzIbNmS3RFWCgg0aJh?usp=drive_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h5>Contabilidad</h5>
                  <p>Ver recursos</p>
                </a>
              </div>
            </div>
            <div className="folder_item-conatiner item-3">
              <div className="folder_item-extraconatiner">
                <a
                  href="https://drive.google.com/drive/folders/1c-Wuk75JGxnjZelJHEt1WO0a36TXmKPU?usp=drive_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h5>Impuestos</h5>
                  <p>Ver recursos</p>
                </a>
              </div>
            </div>
            <div className="folder_item-conatiner item-4">
              <div className="folder_item-extraconatiner">
                <a
                  href="https://drive.google.com/drive/folders/1sGXIGDy8KwqRDLp7VEIkZDHGldmPMOXu?usp=drive_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h5>Laboral</h5>
                  <p>Ver recursos</p>
                </a>
              </div>
            </div>
            <div className="folder_item-conatiner item-5">
              <div className="folder_item-extraconatiner">
                <a
                  href="https://drive.google.com/drive/folders/1LNPJYLjehLZvW-eHuZbSeK36Th3QkM74?usp=drive_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h5>Societario</h5>
                  <p>Ver recursos</p>
                </a>
              </div>
            </div>
          </div>
        </Container>
      )}
    </Layout>
  );
}

export default Dashboard;
