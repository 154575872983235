import React from "react";
import { Container } from "./styled";
import { Layout } from "..";
import { Reviews } from "../../components";

const Contabilidad = () => {
  return (
    <Layout>
      <Container>
        <div className="hero-section" id="contactForm">
          <div className="hero-section-content">
            <div className="hero-section-content-text">
              <h1> Servicios de Contabilidad Integral</h1>
              <p>Somos tu Aliado Estratégico en Contabilidad e Impuestos.</p>
            </div>
            <div className="hero-section-content-form">
              <form action="https://formsubmit.co/your@email.com" method="POST">
                <div className="personal-data">
                  <input
                    placeholder="NOMBRE COMPLETO"
                    type="text"
                    name="nombre"
                    required
                  />
                  <input placeholder="MAIL" type="email" name="mail" required />
                </div>
                <input
                  placeholder="ASUNTO"
                  type="text"
                  name="asunto"
                  required
                />
                <input
                  placeholder="MENSAJE"
                  className="message"
                  type="text"
                  name="mensaje"
                  required
                />
                <button type="submit" className="btn-primary">
                  CONSULTAR
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="cta-section">
          <h1>El mejor Servicio Mensual adaptado a vos</h1>
          <hr className="separation-line" />
          <h3>Soluciones especializadas para la gestión contable</h3>
          <p>
            Ofrecemos una amplia gama de servicios contables integrales para
            empresas, incluyendo asesoramiento contable integral y más.
          </p>
          <a href="#contactForm" className="btn-primary">
            CONSULTANOS
          </a>
        </div>
        <div className="home-options-container">
          <div className="image-container">
            <img
              src="../../assets/services-1.jpg"
              loading="lazy"
              alt="bussines images"
            />
          </div>
          <div className="about-container">
            <h2>Nuestros Servicios Contables</h2>
            <ul>
              <li>Asesoramiento contable integral.</li>
              <li>
                Implementación de sistemas informáticos contables y de costos,
              </li>
              <li>Análisis de cuentas contables.</li>
              <li>Reportes periódicos de resultados.</li>
              <li>Balance General Anual.</li>
              <li>
                Certificaciones relacionadas con aspectos contables para su
                presentación ante bancos, u organismos que lo requieran, ya sea
                de la sociedad, o de sus socios.
              </li>
              <li>
                Desarrollo de tareas contables tendientes a concretar aumentos o
                reducciones de capital.
              </li>
              <li>
                Estados financieros, flujo de fondos para análisis crediticio
                por entidades financieras.
              </li>
              <li>
                Presentación balances en Organismos de contralor: IGJ, DPPJ.
              </li>
              <li>Auditorías de estados contables. </li>
              <li>Auditorías operativas. </li>
              <li>Auditorías de administración de consorcios, </li>
              <li>
                Auditorías de control interno y/o circuitos administrativos.
              </li>
              <li>Auditorias de Control de Gestión. </li>
            </ul>
          </div>
        </div>
        <div className="home-options-container reversed">
          <div className="image-container">
            <img
              src="../../assets/services-2.jpg"
              loading="lazy"
              alt="bussines images"
            />
          </div>
          <div className="about-container">
            <h2>¿Querés asesorarte previamente?</h2>
            <p>
              Iniciar en el ámbito contable e impositivo puede tener sus
              desafíos. En nuestro estudio, no solo ofrecemos servicios
              contables impositivos de primer nivel, sino que también estamos
              aquí para asesorarte.
            </p>
            <p>
              Antes de que te comprometas con un servicio mensual, te brindamos
              toda la orientación que necesitás. Y si estás buscando realizar un
              trámite de inscripción en AFIP, ¡también te cubrimos!
            </p>
            <a href="#contactForm" className="btn-primary ">
              CONSULTANOS
            </a>
          </div>
        </div>
        {/* <Reviews /> */}
      </Container>
    </Layout>
  );
};

export default Contabilidad;
