import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #fff;
  .hero-section {
    background: url("/assets/services-2-hero-m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    margin-top: 10vh;
    height: fit-content;
    padding: 2rem 0;
    .hero-section-content {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;
      justify-content: space-between;
      align-items: center;
      .hero-section-content-text {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1 {
          margin-bottom: 0;
          color: #fff;
        }
        p {
          color: #fff;
        }
      }
      .hero-section-content-form {
        width: 100%;
        form {
          display: flex;
          flex-flow: column;
          input {
            padding: 1rem;
            margin: 1rem 0;
            border-radius: 14px;
            border: 1px solid #cccccc;
            &.message {
              height: 150px;
            }
          }
          .personal-data {
            display: flex;
            justify-content: space-between;
            input {
              width: 40%;
            }
          }
          button {
            max-height: 100px;
            height: 50px;
          }
        }
      }
    }
  }
  .cta-section {
    background: #f6f6f4;
    color: #fff;
    padding: 1.5rem;
    text-align: center;
    height: fit-content;
    h3 {
      color: #13235f;
      font-size: clamp(1rem, 4vw, 3.5rem);
    }
    h1 {
      color: #13235f;
      font-size: clamp(1.3rem, 4vw, 4rem);
    }
    p {
      color: #000;
      text-align: center;
      width: 100%;
      margin: auto;
      margin-bottom: 2rem;
    }
  }
  .home-options-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 3rem auto;
    &.reversed {
      flex-direction: column;
    }
    .image-container {
      width: 100%;
      margin-bottom: 2rem;
      img {
        width: 90%;
      }
    }
    .about-container {
      width: 100%;
      h2 {
        margin: 0;
      }
      h3 {
        color: #13235f;
        margin-bottom: 0;
      }
      p {
        width: 100%;
        font-size: 18px;
      }
      ul {
        padding: 1rem;
        li {
          list-style-image: url("/assets/tick.svg");
        }
      }
    }
  }
  @media (min-width: 867px) {
    .hero-section {
      background: url("/assets/services-2-hero.jpg");
      background-size: cover;
      .hero-section-content {
        flex-direction: row;
        .hero-section-content-text {
          width: 30%;
        }
        .hero-section-content-form {
          width: 50%;
        }
      }
    }
    .cta-section {
      padding: 3rem;
      p {
        width: 70%;
      }
    }
    .home-options-container {
      flex-direction: row;
      &.reversed {
        flex-direction: row-reverse;
      }
      .image-container {
        width: 50%;
        margin-bottom: 0;
      }
      .about-container {
        width: 50%;
      }
    }
  }
`;
